import { types } from 'mobx-state-tree';

const CalibrationDetails = types.model({
  accuracy_ratio: types.maybeNull(types.number),
  calibration_attempts: types.maybeNull(types.number),
  power: types.maybeNull(types.number),
  signal: types.maybeNull(types.number),
});

const DetectorOptions = types.model({
  consecutiveZeroSamples: types.maybeNull(types.number),
  envelope: types.maybeNull(types.number),
  flowWindow: types.maybeNull(types.number),
  label: types.maybeNull(types.string),
  leadingLength: types.maybeNull(types.number),
  startCount: types.maybeNull(types.number),
  suspectN: types.maybeNull(types.number),
  suspectZeroMlPerMsStdevpX: types.maybeNull(types.number),
  trailingLength: types.maybeNull(types.number),
  zeroWindow: types.maybeNull(types.number),
});

const Metadata = types.model({
  ADC_ADDITIONAL_CAPTURE_DELAY: types.maybeNull(types.number),
  CALIBRATED_DC_OFFSET_DNS: types.maybeNull(types.number),
  CALIBRATED_DC_OFFSET_UPS: types.maybeNull(types.number),
  CALIBRATED_TOF: types.maybeNull(types.number),
  CALIBRATED_TOF_TEMP: types.maybeNull(types.number),
  CAPTURE_DURATION: types.maybeNull(types.number),
  DC_OFFSET: types.maybeNull(types.number),
  DELAY_START_CAPTURE: types.maybeNull(types.number),
  PGA_GAIN: types.maybeNull(types.number),
  SAMPLE_INTERVAL_US: types.maybeNull(types.number),
  VOLUME_SCALE_FACTOR: types.maybeNull(types.number),
  XD_FREQ_KHZ: types.maybeNull(types.number),
  XD_NPULSES: types.maybeNull(types.number),
});

export const Sensor = types.model({
  id: types.integer,

  archived: types.boolean,
  bru_id: types.maybeNull(types.integer),
  bru_sensor_address: types.maybeNull(types.integer),
  calibration_details: types.maybeNull(CalibrationDetails),
  calibration_status_code: types.maybeNull(types.integer),
  enumerated: types.boolean,
  enumerated_at: types.string,
  firmware_version: types.maybeNull(types.integer),
  hardware_version: types.maybeNull(types.integer),
  latest_calibration_at: types.maybeNull(types.string),
  latest_calibration_by: types.maybeNull(types.integer),
  latest_heartbeat_id: types.maybeNull(types.integer),
  latest_heartbeat_received_at: types.maybeNull(types.string),
  latest_pour_id: types.maybeNull(types.integer),
  latest_pour_poured_at: types.maybeNull(types.string),
  latest_pour_received_at: types.maybeNull(types.string),
  type: types.maybeNull(types.integer),
  _gateways_establishment_id: types.maybeNull(types.integer),
  _gateways_id: types.maybeNull(types.integer),
  metadata: types.maybeNull(Metadata),
  detector_options: types.maybeNull(DetectorOptions),
  _users_display_name_latest_calibration_by: types.maybeNull(types.string),
});
