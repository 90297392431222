import { types, flow } from 'mobx-state-tree';

import api from 'services/API';
import { getRootStore } from 'models/root';

import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';

import { ItemEvent } from 'models/types';
import {
  generateEvent,
  appliedToFieldMap,
  eventTypeMap,
  allowed_fields,
  handleUnusualEvents,
} from 'config/itemEventsStatuses';
import { dateUtilities } from 'utils';

export const itemEventsInitialState = {
  period: {
    from: null,
    to: null,
  },
  all: [],
  state: 'done',
  updated: null,
  filters: {
    type: [],
    event: [],
    employees: 'all',
    sortedBy: 'timestamp',
    orderBy: 'desc',
  },
  searchString: '',
  userId: null,
};

export const ItemEventWithViews = ItemEvent.views(self => ({
  get item() {
    const root = getRootStore();
    return root.itemsStore.all.find(item => item.id === self.item_id);
  },

  get user() {
    const root = getRootStore();
    return root.usersStore.all.find(user => user.email === self._users_username);
  },
}));

export const itemEventsModel = types
  .model({
    period: types.model({
      from: types.maybeNull(types.string),
      to: types.maybeNull(types.string),
    }),
    all: types.array(ItemEventWithViews),
    filters: types.maybeNull(
      types.model({
        type: types.array(types.string),
        event: types.array(types.string),
        employees: types.string,
        sortedBy: types.enumeration('sortedBy', ['event', 'applied_to', 'type', 'timestamp']),
        orderBy: types.enumeration('orderBy', ['asc', 'desc']),
      }),
    ),
    state: types.enumeration('state', ['done', 'pending', 'error']),
    updated: types.maybeNull(types.Date),
    userId: types.maybeNull(types.integer),
  })
  .views(self => ({
    get itemEvents() {
      let filtered = self.all.slice();

      if (self.searchString) {
        const searchArray = self.searchString?.toLowerCase().split(' ');

        filtered = self.all.filter(item =>
          searchArray.every(element => appliedTo(item)?.toLowerCase().includes(element)),
        );
      }

      if (Array.isArray(self.filters.type) && self.filters.type.length > 0) {
        let types = self.filters.type.map(item => {
          return Object.keys(eventTypeMap).filter(key => eventTypeMap[key] === item);
        });

        types = types.reduce((acc, item) => {
          item.forEach(i => acc.push(i));
          return acc;
        }, []);

        filtered = filtered.filter(item => {
          return types.includes(item.collection_name);
        });
      }

      if (Array.isArray(self.filters.event) && self.filters.event.length > 0) {
        filtered = filtered.filter(item => {
          return Array.from(self.filters.event).includes(generateEvent(handleUnusualEvents(item)));
        });
      }

      if (self.filters.employees.toLowerCase() !== 'all') {
        filtered = filtered.filter(
          item => item._users_display_name_changed_by === self.filters.employees,
        );
      }

      let result = [];
      let grouped = groupBy(filtered, 'collection_name');

      Object.keys(grouped).forEach(collection_name => {
        grouped[collection_name] = groupBy(
          grouped[collection_name].map(item => handleUnusualEvents(item)),
          'operation',
        );
      });

      Object.keys(grouped).forEach(collection_name => {
        Object.keys(grouped[collection_name]).forEach(operation => {
          if (grouped[collection_name][operation].length > 1) {
            const sorted = orderBy(grouped[collection_name][operation], ['changed_at'], ['desc']);

            let grouped_by_timestamp = [];
            sorted.forEach((item, index) => {
              if (index === 0) {
                grouped_by_timestamp.push({
                  applied_to: appliedTo(item),
                  operation: item.operation,
                  type: eventTypeMap[item.collection_name] || item.collection_name,
                  change_type: item.change_type,
                  collection: item.collection_name,
                  timestamp: item.changed_at,
                  expandable: false,
                  event: generateEvent(item),
                  details: details(item),
                  modified_by: item._users_display_name_changed_by,
                  items: [
                    {
                      applied_to: appliedTo(item),
                      operation: item.operation,
                      type: eventTypeMap[item.collection_name] || item.collection_name,
                      change_type: item.change_type,
                      collection: item.collection_name,
                      timestamp: item.changed_at,
                      expandable: false,
                      event: generateEvent(item),
                      details: details(item),
                      modified_by: item._users_display_name_changed_by,
                    },
                  ],
                });
              } else {
                const prevItem = sorted[index - 1];
                const timeDiff = Math.abs(
                  new Date(item.changed_at) - new Date(prevItem.changed_at),
                );
                const minutesDiff = Math.floor(timeDiff / 60000);

                if (minutesDiff <= 5) {
                  grouped_by_timestamp[grouped_by_timestamp.length - 1] = {
                    ...grouped_by_timestamp[grouped_by_timestamp.length - 1],
                    applied_to: 'Multiple Items',
                    expandable: true,
                    items: [
                      ...(grouped_by_timestamp[grouped_by_timestamp.length - 1]?.items || []),
                      {
                        applied_to: appliedTo(item),
                        operation: item.operation,
                        type: eventTypeMap[item.collection_name] || item.collection_name,
                        change_type: item.change_type,
                        collection: item.collection_name,
                        timestamp: item.changed_at,
                        expandable: true,
                        event: generateEvent(item),
                        details: details(item),
                        modified_by: item._users_display_name_changed_by,
                      },
                    ],
                  };
                } else {
                  grouped_by_timestamp.push({
                    applied_to: appliedTo(item),
                    operation: operation,
                    type: eventTypeMap[collection_name] || collection_name,
                    change_type: item.change_type,
                    collection: item.collection_name,
                    timestamp: item.changed_at,
                    expandable: false,
                    event: generateEvent(item),
                    details: details(item),
                    modified_by: item._users_display_name_changed_by,
                    items: [
                      {
                        applied_to: appliedTo(item),
                        operation: operation,
                        type: eventTypeMap[collection_name] || collection_name,
                        change_type: item.change_type,
                        collection: collection_name,
                        timestamp: item.changed_at,
                        expandable: false,
                        event: generateEvent(item),
                        details: details(item),
                        modified_by: item._users_display_name_changed_by,
                      },
                    ],
                  });
                }
              }
            });

            result.push(...grouped_by_timestamp);
          } else {
            result.push({
              applied_to: appliedTo(grouped[collection_name][operation][0]),
              operation: operation,
              type: eventTypeMap[collection_name] || collection_name,
              change_type: grouped[collection_name][operation][0].change_type,
              collection: collection_name,
              timestamp: grouped[collection_name][operation][0].changed_at,
              user_id: grouped[collection_name][operation][0].changed_by,
              expandable: false,
              event: generateEvent(grouped[collection_name][operation][0]),
              items: [],
              details: details(grouped[collection_name][operation][0]),
              modified_by: grouped[collection_name][operation][0]._users_display_name_changed_by,
            });
          }
        });
      });

      // Sort/Order operations
      result = orderBy(result, [self.filters.sortedBy], [self.filters.orderBy]);

      result.forEach((item, index) => {
        result[index] = {
          ...result[index],
          items: (result[index].items = orderBy(
            item.items,
            [self.filters.sortedBy],
            [self.filters.orderBy],
          )),
        };
      });

      return result;
    },

    get isFiltersChanged() {
      const { type, event, employees, sortedBy, orderBy } = self.filters;
      const defaultTypeState = type.length === 0 || type.length === self.eventsType.length;
      const defaultEventState = event.length === 0 || event.length === self.events.length;

      return Boolean(
        !defaultTypeState ||
          !defaultEventState ||
          employees.toLowerCase() !== 'all' ||
          sortedBy !== 'timestamp' ||
          orderBy !== 'desc',
      );
    },

    get isFiltersChangedByUser() {
      const { type, event, sortedBy, orderBy } = self.filters;
      const defaultTypeState = type.length === 0 || type.length === self.eventsType.length;
      const defaultEventState = event.length === 0 || event.length === self.events.length;

      return Boolean(
        !defaultTypeState || !defaultEventState || sortedBy !== 'timestamp' || orderBy !== 'desc',
      );
    },

    get users() {
      return orderBy(
        uniqBy(self.all, '_users_display_name_changed_by')
          .map(item => item._users_display_name_changed_by)
          .filter(item => item !== ''),
        [],
        ['asc'],
      );
    },

    get events() {
      if (!self.filters.type.length) {
        return uniq(self.all.map(item => generateEvent(handleUnusualEvents(item))));
      }

      let types = self.filters.type.map(item => {
        return Object.keys(eventTypeMap).filter(key => eventTypeMap[key] === item);
      });

      types = types.reduce((acc, item) => {
        item.forEach(i => acc.push(i));
        return acc;
      }, []);

      const items = self.all.filter(item => {
        return types.includes(item.collection_name);
      });

      self.setFilters({
        ...self.filters,
        event: orderBy(
          uniq(items.map(item => generateEvent(handleUnusualEvents(item)))),
          [],
          ['asc'],
        ),
      });

      return orderBy(
        uniq(items.map(item => generateEvent(handleUnusualEvents(item)))),
        [],
        ['asc'],
      );
    },

    get eventsType() {
      return orderBy(uniq(self.all.map(item => eventTypeMap[item.collection_name])), [], ['asc']);
    },

    get userItemEvents() {
      return self.itemEvents; // temp DO NOT DEPLOY
      // if (!self.userId) return [];

      // return self.itemEvents.filter(event => {
      //   if (event.items) {
      //     event.items = event.items.filter(item => item.user_id === self.userId);

      //     return Boolean(event.items.length);
      //   }

      //   return event.user_id === self.userId;
      // });
    },
  }))
  .actions(self => {
    return {
      fetchItemEvents: flow(function* () {
        try {
          self.state = 'pending';

          let { from, to } = self.period;

          if (!from || !to) {
            ({ from, to } = dateUtilities.getDatesByPeriod('this-week'));
          }

          const response = yield api.getItemEvents({
            'from[changed_at]': from,
            'to[changed_at]': to,
          });

          if (response?.data?.result) {
            self.all.replace(response.data.result);
            self.state = 'done';
            self.isLoaded = true;
            self.updated = new Date();
            return response.data.result;
          } else {
            self.isLoaded = false;
            self.state = 'done';
            self.updated = new Date();
          }
        } catch (error) {
          self.isLoaded = false;
          self.state = 'error';
          self.updated = new Date();
          console.error(error);
          return Promise.reject(error);
        }
      }),

      setPeriod(period) {
        self.period = period;
      },

      updateOrInsertItemEvent(_event) {
        if (_event && _event.id) {
          const eventIndex = self.all.findIndex(event => event.id === _event.id);
          const eventExist = eventIndex >= 0;
          if (eventExist) {
            self.all[eventIndex] = _event;
          } else {
            const updatedEvents = [...self.all, _event];
            self.all.replace(updatedEvents);
          }
        }
      },

      handleAddItem(result) {
        const [added] = result?.added || [];

        if (Array.isArray(added) && added.length > 0) {
          added.forEach(el => {
            if (el._item_event) {
              self.updateOrInsertItemEvent(el._item_event);
            }
          });
        }
      },

      handleNewItemLine(result) {
        if (result?.added?.[0]?._item_event) {
          self.updateOrInsertItemEvent(result?.added?.[0]?._item_event);
        }
      },

      handleItemLineRemove(result) {
        const removed = result?.removed || [];

        if (Array.isArray(removed) && removed.length > 0) {
          removed.forEach(removedEl => {
            if (removedEl?._item_event) {
              self.updateOrInsertItemEvent(removedEl?._item_event);
            }
          });
        }
      },

      handleRemoveItem(result) {
        if (result && result._item_event) {
          self.updateOrInsertItemEvent(result._item_event);
        }
      },

      handleNextItem({ disconnected = {}, connected = {} } = {}) {
        const { _item_events: disconnectedItemEvents } = disconnected;
        const { _item_events: connectedItemEvents } = connected;

        disconnectedItemEvents &&
          Array.isArray(disconnectedItemEvents) &&
          disconnectedItemEvents.forEach(self.updateOrInsertItemEvent);
        connectedItemEvents &&
          Array.isArray(connectedItemEvents) &&
          connectedItemEvents.forEach(self.updateOrInsertItemEvent);
      },

      handleSwapItems({ _item_events_created = [], _item_events_updated = [] } = {}) {
        _item_events_created &&
          Array.isArray(_item_events_created) &&
          _item_events_created.forEach(self.updateOrInsertItemEvent);
        _item_events_updated &&
          Array.isArray(_item_events_updated) &&
          _item_events_updated.forEach(self.updateOrInsertItemEvent);
      },

      setSearchString(value) {
        self.searchString = value;
      },

      setFilters(filters) {
        if (filters === null) {
          self.filters = {
            type: [],
            event: [],
            employees: 'all',
            sortedBy: 'timestamp',
            orderBy: 'desc',
          };
        } else {
          self.filters = {
            type: filters.type || self.filters.type,
            event: filters.event || self.filters.event,
            employees: filters.employees || self.filters.employees,
            sortedBy: filters.sortedBy || self.filters.sortedBy,
            orderBy: filters.orderBy || self.filters.orderBy,
          };
        }
      },

      setItemEvents(itemEvents) {
        self.all.replace(itemEvents);
      },
      setUserId(id) {
        self.userId = id;
      },
    };
  });

/******************************************/
// Here start section with helper functions

const details = item => {
  let keys = [];
  let result = [];

  if (!Boolean(item?.change_after) || !Boolean(item?.change_before)) {
    return [];
  }

  if (item?.change_after?.keys()) {
    keys.push(...Array.from(item?.change_after?.keys() || []));
  }

  if (item?.change_before?.keys()) {
    keys.push(...Array.from(item?.change_before?.keys() || []));
  }

  keys = uniq(keys).filter(key => allowed_fields.includes(key));

  keys.forEach(key => {
    const item1 = item?.change_before?.get(key) || undefined;
    const item2 = item?.change_after?.get(key) || undefined;

    if (item1 !== item2) {
      handleNestedDetailsObjects(item1, item2, key, result);
    }
  });

  return result;
};

const handleNestedDetailsObjects = (item1, item2, key, resultArr) => {
  if (typeof item1 === 'object' && typeof item2 === 'object') {
    let nestedKeys = [];

    if (Object.keys(item1 || {})) {
      nestedKeys.push(...Array.from(Object.keys(item1 || {}) || []));
    }

    if (Object.keys(item2 || {})) {
      nestedKeys.push(...Array.from(Object.keys(item2 || {}) || []));
    }

    nestedKeys = uniq(nestedKeys).filter(key => allowed_fields.includes(key));

    nestedKeys.forEach((nestedKey, index) => {
      const nestedItem1 = item1?.[nestedKey] || undefined;
      const nestedItem2 = item2?.[nestedKey] || undefined;

      if (nestedItem1 !== nestedItem2) {
        handleNestedDetailsObjects(nestedItem1, nestedItem2, key, resultArr);
      }
    });
  } else {
    resultArr.push({
      key,
      oldValue: item1,
      newValue: item2,
    });
  }
};

const appliedTo = item => {
  const [subject] = uniq([
    item?.change_before?.get(appliedToFieldMap[item.collection_name]),
    item?.change_after?.get(appliedToFieldMap[item.collection_name]),
  ]);

  if (subject) return subject;

  const more_details = {
    ...(item?.change_before?.get('_more_details') || {}),
    ...(item?.change_after?.get('_more_details') || {}),
  };

  const [value] = Object.values(more_details).filter(item => typeof item !== 'object');

  return value;
};
