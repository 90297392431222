import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { Paper, Button, styled } from '@mui/material';

import clsx from 'clsx';
import { useStore } from 'hooks';
import { dateUtilities } from 'utils';

import CalendarDrawer from './CalendarDrawer';

const PeriodButtonGroup = ({ className }) => {
  const { topologyManagementStore } = useStore();

  const [activePeriod, setActivePeriod] = useState('1');
  const [openCalendarDrawer, setOpenCalendarDrawer] = useState(false);

  const handleChangePeriod = ({ target: { value } }) => {
    setActivePeriod(value);
    const { from, to } = dateUtilities.getDatesByPeriod(value);

    topologyManagementStore.setPeriod({
      from,
      to,
    });
  };

  const isActive = value => {
    return activePeriod === value;
  };

  const handleSelectCustom = () => {
    setActivePeriod('custom');
    setOpenCalendarDrawer(true);
  };

  const handleConfirmCustomPeriod = period => {
    const { from, to } = dateUtilities.getDatesByPeriod('custom', {
      fromDate: period.from,
      toDate: period.to,
    });

    topologyManagementStore.setPeriod({
      from,
      to,
    });
  };

  const options = [
    { value: '1', label: '24H', handler: handleChangePeriod },
    { value: '7', label: '7D', handler: handleChangePeriod },
    { value: '14', label: '14D', handler: handleChangePeriod },
    { value: '30', label: '30D', handler: handleChangePeriod },
    { value: 'custom', label: 'Custom', handler: handleSelectCustom },
  ];

  return (
    <StyledRootPaper elevation={0} className={className}>
      {options.map(({ value, label, handler }, index) => (
        <Button
          key={`option_period_mobile-${index}`}
          value={value}
          className={clsx('period_button', { active: isActive(value) })}
          onClick={handler}
        >
          {label}
        </Button>
      ))}

      <CalendarDrawer
        open={openCalendarDrawer}
        onClose={() => setOpenCalendarDrawer(false)}
        onApply={handleConfirmCustomPeriod}
      />
    </StyledRootPaper>
  );
};

export default observer(PeriodButtonGroup);

const StyledRootPaper = styled(Paper)(({ theme: { palette } }) => ({
  borderRadius: '12px',
  padding: '2px',
  height: 'fit-content',

  '.period_button': {
    borderRadius: '8px',
    height: '24px',
    textTransform: 'uppercase',
    fontSize: '10px',
    color: palette.whiteEmphasis.medium,
    minWidth: '70px',

    '&.active': {
      backgroundColor: palette.background.overlay8,
      color: palette.whiteEmphasis.high,
    },

    '&:not(:last-of-type) ': {
      marginRight: '5px',
    },
  },
}));
