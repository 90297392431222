import { useLayoutEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

function useUserAgent() {
  //   const [size, setSize] = useState([0, 0]);
  const [ua, setUa] = useState(null);
  useLayoutEffect(() => {
    function updateUa() {
      setUa(UAParser(window.navigator.userAgent));
    }
    updateUa();
  }, []);

  return ua;
}

export default useUserAgent;
