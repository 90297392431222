import React, { useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import {
  Box,
  Badge,
  DialogContent,
  IconButton,
  Menu,
  Typography,
  styled,
  DialogTitle,
} from '@mui/material';
import {
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  WarningAmber as WarningAmberIcon,
} from '@mui/icons-material';

import { useStore } from 'hooks';

import { useNavigate } from 'react-router-dom';

import { dateUtilities } from 'utils';
import Dialog from 'components/shared/dialogs/Dialog';

const NotificationsMenu = () => {
  const navigate = useNavigate();
  const { notificationsStore, deviceHistoryStore } = useStore();

  let iconEl = null;
  const [anchorEl, setAnchorEl] = useState(null);
  const [offset, setOffset] = useState(0);

  const openNotifications = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  setTimeout(() => {
    iconEl = document.getElementById('notification_icon');
    const temp = iconEl?.getBoundingClientRect();
    setOffset(temp?.x + 4);
  }, 100);

  const handleClickItem = useCallback(
    id => {
      deviceHistoryStore.setActiveTab('alerts');

      navigate('/quality-management/system-health');
      notificationsStore.setAlertId(id);

      notificationsStore.markAsRead(id);

      setTimeout(() => setAnchorEl(null), 100);
    },
    [deviceHistoryStore, notificationsStore, navigate],
  );

  const handleClickSetting = useCallback(() => {
    setAnchorEl(null);
    navigate('/my-account/notification-settings');
  }, [navigate]);

  const contentBox = useMemo(
    () => (
      <StyledContentBox className="content_box">
        {notificationsStore.unread.length ? (
          notificationsStore.unread.map(
            (
              { id, _conditions_earliest_trigger_at, _alarms_alert_caption, _coolers_cooler_name },
              index,
            ) => (
              <Box className="menu_item" key={index}>
                <Box className="date_box" justifyContent="end">
                  <Typography className="subtitle">
                    {dateUtilities.formatDateIncludingToday(_conditions_earliest_trigger_at)}
                  </Typography>
                </Box>
                <Box className="row_box">
                  <Box className="icon_box">
                    <WarningAmberIcon />
                  </Box>
                  <Box className="text_box">
                    <Typography className="title">{_alarms_alert_caption + ' Alert'}</Typography>
                    <Typography className="subtitle">{_coolers_cooler_name}</Typography>
                  </Box>
                  <Box className="arrow_box">
                    <IconButton onClick={() => handleClickItem(id)}>
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            ),
          )
        ) : (
          <Typography display="flex" justifyContent="center" mt={1}>
            No new notifications
          </Typography>
        )}
      </StyledContentBox>
    ),
    [handleClickItem, notificationsStore.unread],
  );

  const MobileMenu = (
    <StyledRootDialog
      open={!!anchorEl}
      title=""
      fullWidth
      maxWidth="xs"
      onClose={handleClose}
      mobileHeaderRight={<Box width={30}></Box>}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" sx={{ width: '100%' }}>
          <IconButton onClick={handleClickSetting}>
            <SettingsIcon className="settings_icon" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>{contentBox}</DialogContent>
    </StyledRootDialog>
  );

  const DesktopMenu = (
    <StyledMenu
      anchorReference="anchorPosition"
      anchorPosition={{ top: 48, left: offset + 210 }}
      open={!!anchorEl}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      sx={{ '&::before': { left: offset } }}
    >
      <Box className="root_box">
        <Box className="title_box">
          <Typography fontSize={24}>Notifications</Typography>
          <IconButton onClick={handleClickSetting}>
            <SettingsIcon className="settings_icon" />
          </IconButton>
        </Box>
        {contentBox}
      </Box>
    </StyledMenu>
  );

  return (
    <>
      <IconButton
        id="notification_icon"
        edge="end"
        aria-label="notifications"
        className="notificationsIcon"
        onClick={openNotifications}
      >
        <Badge
          color="error"
          invisible={!notificationsStore.unread.length}
          badgeContent={notificationsStore.unread.length}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {MobileMenu}
      {!!anchorEl && DesktopMenu}
    </>
  );
};

export default observer(NotificationsMenu);

const StyledContentBox = styled(Box)(({ theme: { palette, breakpoints } }) => ({
  padding: '57px 17px 12px 0',
  maxHeight: '525px',
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
  boxSizing: 'content-box',

  [breakpoints.down('sm')]: {
    padding: 0,
  },

  '.menu_item': {
    marginLeft: '16px',
    height: '81px',
    borderBottom: '1px solid #313131',
    position: 'relative',
    top: '8px',
  },

  '.date_box': {
    width: '100%',
    display: 'flex',
    position: 'relative',
    top: '5px',
  },

  '.row_box': {
    width: '100%',
    display: 'flex',
    height: '48px',
    alignItems: 'center',
  },

  '.icon_box': {
    height: '24px',
    width: '24px',
    padding: '12px',
    marginRight: '16px',
    borderRadius: '8px',
    backgroundColor: palette.background.main,

    '& svg': {
      color: palette.status.red,
    },
  },

  '.title': {
    fontSize: '14px',
    color: palette.whiteEmphasis.high,
  },
  '.subtitle': {
    fontSize: '11px',
    color: palette.whiteEmphasis.medium,
  },

  '.arrow_box': {
    position: 'absolute',
    right: 0,
    cursor: 'pointer',

    '& svg': {
      color: palette.whiteEmphasis.medium,
    },
  },
}));

const StyledMenu = styled(Menu)(({ theme: { palette, breakpoints } }) => ({
  borderRadius: '8px',
  border: '1px solid #313131',
  [breakpoints.down('sm')]: {
    display: 'none',
  },

  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 55,
    width: 0,
    height: 0,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: `10px solid ${palette.secondary.greyLight}`,
    backgroundColor: 'transparent',
    zIndex: 3,
  },
  '.MuiPaper-root': {
    width: '400px',
    maxHeight: '600px',
    position: 'absolute',
    right: '16px !important',
    margin: '16px',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    backgroundColor: `${palette.secondary.greyLight} !important`,
    border: '1px solid #313131',
    borderRadius: '12px',
  },

  '.root_box': {
    display: 'block',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },

  '.title_box': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    position: 'fixed',
    top: 0,
    width: '100%',
    borderBottom: `1px solid ${palette.outline.main}`,
    height: '60px',
    backgroundColor: palette.secondary.greyLight,
    zIndex: 1200,
  },

  '.settings_icon': {
    color: palette.whiteEmphasis.medium,
  },
}));

const StyledRootDialog = styled(Dialog)(({ theme: { palette, breakpoints } }) => ({
  [breakpoints.up('sm')]: {
    display: 'none',
  },

  '.titleDark': {
    background: palette.secondary.main,
  },
}));
