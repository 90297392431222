import React from 'react';
import { observer } from 'mobx-react';

import { Box, Tooltip } from '@mui/material';

import {
  Cellular,
  CellularFailed,
  EthernetOnline,
  EthernetFailed,
  WifiFailed,
  Wifi,
} from 'assets/icons';

import { useMediaQueries } from 'hooks';

import dateUtilities from 'utils/dateUtilities';
import palette from 'theme/palette';

const OnlineStatus = ({
  date = null,
  gateway = null,
  replaced = false,
  muted = false,
  disabled = false,
  iconStyles = {},
}) => {
  const { isTabletOrSmaller } = useMediaQueries();

  let onlineState = false;
  let title = 'No Data';

  if (gateway) {
    const { latest_heartbeat_received_at, latest_sample_received_at } = gateway;
    const mostRecentDate = dateUtilities.getMostRecentDate([
      latest_heartbeat_received_at,
      latest_sample_received_at,
    ]);

    if (mostRecentDate) {
      onlineState = dateUtilities.isOnline(mostRecentDate);
      title = dateUtilities.formatDateIncludingToday(mostRecentDate, {
        applyEstablishmentOffset: false,
      });
    }
  } else if (date) {
    onlineState = dateUtilities.isOnline(date);
    title = dateUtilities.formatDateIncludingToday(date, {
      applyEstablishmentOffset: false,
    });
  }

  const mobileCommonStyle = {
    marginRight: '12px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  };
  const desktopCommonStyle = {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
  };

  let iconBox = (
    <Box
      sx={{
        backgroundColor: onlineState ? palette.status.green : palette.status.red,
        ...(isTabletOrSmaller ? mobileCommonStyle : desktopCommonStyle),
        ...iconStyles,
      }}
    />
  );

  const replacedIcon = (
    <Box
      sx={{
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: palette.whiteEmphasis.low,
        backgroundColor: 'transparent',
        ...(isTabletOrSmaller ? mobileCommonStyle : desktopCommonStyle),
        ...iconStyles,
      }}
    />
  );

  const mutedDisabledIcon = (
    <Box
      sx={{
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: palette.status.red,
        backgroundColor: 'transparent',
        ...(isTabletOrSmaller ? mobileCommonStyle : desktopCommonStyle),
        ...iconStyles,
      }}
    />
  );

  if (gateway?.metadata?.network_connectivity_mode) {
    const icon_map = {
      wifi: onlineState ? <Wifi /> : <WifiFailed />,
      cellular: onlineState ? <Cellular /> : <CellularFailed />,
      ethernet: onlineState ? <EthernetOnline className="mt" /> : <EthernetFailed className="mt" />,
    };

    iconBox = (
      <Box
        sx={{
          position: 'absolute',
          top: '8px',
          right: '16px',
          width: '10px',
          height: '10px',
          ...iconStyles,
        }}
      >
        {icon_map[gateway.metadata.network_connectivity_mode]}
      </Box>
    );
  }

  if (replaced) {
    iconBox = replacedIcon;
  }

  if (muted || disabled) {
    iconBox = mutedDisabledIcon;
  }

  return isTabletOrSmaller ? iconBox : <Tooltip title={title}>{iconBox}</Tooltip>;
};

export default observer(OnlineStatus);
