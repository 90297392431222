import userStatuses from 'config/userStatuses';
import { getRootStore } from 'models/root';
import { UserProfile } from 'models/types/userProfile';

export const UserProfileWithViews = UserProfile.views(self => ({
  get isYou() {
    const root = getRootStore();
    if (root?.userStore?.profile?.id) {
      return self.id === root.userStore.profile.id;
    }
    return false;
  },

  get isBarTrackUser() {
    if (Array.isArray(self._roles)) {
      return self._roles.some(role => role && role._role_name === 'BarTrack');
    }
    return false;
  },

  get isOwner() {
    if (Array.isArray(self._roles)) {
      return self._roles.some(
        role =>
          role &&
          !role._roleuser_locked &&
          role._roleuser_accepted_at &&
          role._role_name === 'Owner',
      );
    }
    return false;
  },

  get isManager() {
    if (Array.isArray(self._roles)) {
      return self._roles.some(
        role =>
          role &&
          !role._roleuser_locked &&
          role._roleuser_accepted_at &&
          role._role_name === 'Manager',
      );
    }
    return false;
  },

  get fullName() {
    const { first_name, last_name } = self;
    return first_name || last_name ? (first_name && first_name + ' ') + last_name : '';
  },

  get status() {
    if (Array.isArray(self._roles) && self._roles.length > 0) {
      const userHasAcceptedRole = self._roles.find(role => role._roleuser_accepted_at);
      if (!userHasAcceptedRole) {
        return userStatuses.pending;
      }
    }
    return self._valid !== 0 ? userStatuses.active : userStatuses.locked;
  },

  get roleName() {
    return self.currentEstablishmentRole._role_name || '';
  },

  get currentEstablishmentRole() {
    const root = getRootStore();
    const currentEstablishmentId = root.userStore.currentRole._establishment_id;

    if (Array.isArray(self._roles) && self._roles.length > 0) {
      return self._roles.find(role => role._establishment_id === currentEstablishmentId);
    }
    return {};
  },

  get isCurrentEstablishmentRoleAccepted() {
    return Boolean(self?.currentEstablishmentRole._roleuser_accepted_at);
  },

  get roles() {
    if (Array.isArray(self._roles) && self._roles.length > 0) {
      return self._roles.filter(role =>
        self.status === userStatuses.pending
          ? !Boolean(role._roleuser_accepted_at)
          : Boolean(role._roleuser_accepted_at),
      );
    }
    return [];
  },

  get bars() {
    const root = getRootStore();

    return root.barsStore.availableBars.filter(bar => self.barsIds.includes(bar.id));
  },
  get barsIds() {
    const root = getRootStore();

    return root.barsRoleUserStore.bars
      .filter(bar => bar.role_user_id === self?.currentEstablishmentRole._roleuser_id)
      .map(({ bar_id }) => bar_id);
  },
  get defaultBar() {
    const root = getRootStore();
    if (self._bar_id === null) return null;

    return root.barsStore.availableBars.find(bar => bar.id === self._bar_id[0]);
  },

  get linkedAccounts() {
    return Object.entries(self.auth_info)
      .map(([key, val]) => val && key)
      .filter(val => val);
  },

  get devices() {
    const root = getRootStore();
    return root.notificationsStore.devices.filter(e => e.user_id === self.id) || [];
  },

  get hasDevices() {
    return !!self.devices.length;
  },

  get hasUnmutedDevice() {
    return !!self.devices.length && self.devices?.some(e => !e.notifications_muted);
  },
}));
