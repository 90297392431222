import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Button, Box, IconButton, Typography, styled } from '@mui/material';
import { Close } from '@mui/icons-material';

import { useStore, useToast } from 'hooks';
import { dateUtilities } from 'utils';

import CustomDatePicker from './CustomDatePicker';

const PeriodButtonGroup = ({ className }) => {
  const { topologyManagementStore } = useStore();

  const { errorToast } = useToast();

  const [activePeriod, setActivePeriod] = useState('1');
  const [datePickerAnchor, setDatePickerAnchor] = useState(null);
  const [badgePeriod, setBadgePeriod] = useState(null);

  const ref = useRef(null);

  const handleChangePeriod = async ({ target: { value } }) => {
    setActivePeriod(value);

    const { from, to } = dateUtilities.getDatesByPeriod(value);

    await topologyManagementStore.setPeriod({
      from,
      to,
    });

    ref?.current?.reset();
    setBadgePeriod(null);
    setDatePickerAnchor(null);
  };

  const isActive = value => {
    return activePeriod === value;
  };

  const handleSelectCustom = event => {
    setActivePeriod('custom');
    setDatePickerAnchor(event.currentTarget);
  };

  const handleConfirmCustomPeriod = async period => {
    try {
      const { from, to } = dateUtilities.getDatesByPeriod('custom', {
        fromDate: period.from,
        toDate: period.to,
      });

      await topologyManagementStore.setPeriod({
        from,
        to,
      });

      setBadgePeriod({
        from: format(new Date(period.from), 'MMM dd'),
        to: format(new Date(period.to), 'MMM dd'),
      });

      setDatePickerAnchor(null);
    } catch (e) {
      errorToast('An error occurred while fetching device details');
    }
  };

  const handleCloseCustomDatePicker = () => {
    // A little trick to reuse function
    handleChangePeriod({ target: { value: '1' } });
  };

  const options = [
    { value: '1', label: '24H', handler: handleChangePeriod },
    { value: '7', label: '7D', handler: handleChangePeriod },
    { value: '14', label: '14D', handler: handleChangePeriod },
    { value: '30', label: '30D', handler: handleChangePeriod },
    { value: 'custom', label: 'Custom', handler: handleSelectCustom },
  ];

  return (
    <StyledRootBox className={className}>
      {activePeriod === 'custom' && badgePeriod && (
        <Box className="period_badge">
          <Typography className="badge_title">{`${badgePeriod.from} - ${badgePeriod.to}`}</Typography>
          <IconButton className="badge_icon" onClick={handleCloseCustomDatePicker}>
            <Close />
          </IconButton>
        </Box>
      )}

      <Box elevation={0} className="paper_box">
        {options.map(({ value, label, handler }, index) => (
          <Button
            key={`option_period-${index}`}
            value={value}
            className={clsx('period_button', { active: isActive(value) })}
            onClick={handler}
          >
            {label}
          </Button>
        ))}

        <CustomDatePicker
          ref={ref}
          anchor={datePickerAnchor}
          onClose={handleCloseCustomDatePicker}
          onConfirm={handleConfirmCustomPeriod}
        />
      </Box>
    </StyledRootBox>
  );
};

export default observer(PeriodButtonGroup);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: palette.background.main,
  borderRadius: '8px',

  '.period_badge': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '36px',
    padding: '6px 10px',
    backgroundColor: palette.background.overlay2,
    marginRight: '8px',
  },

  '.badge_title': {
    fontSize: '11px',
    color: palette.whiteEmphasis.high,
    marginRight: '8px',
  },

  '.badge_icon': {
    width: '18px',
    height: '18px',
    backgroundColor: palette.background.overlay8,

    '& svg': {
      width: '14px',
      height: '14px',
      color: '#151515',
    },
  },

  '.paper_box': {
    borderRadius: '8px',
    padding: '2px',
    height: 'fit-content',
  },

  '.period_button': {
    borderRadius: '8px',
    height: '24px',
    textTransform: 'uppercase',
    fontSize: '10px',
    color: palette.whiteEmphasis.medium,
    minWidth: '70px',

    '&.active': {
      backgroundColor: palette.background.overlay8,
      color: palette.whiteEmphasis.high,
    },

    '&:not(:last-of-type) ': {
      marginRight: '2px',
    },
  },
}));
