import { types, flow } from 'mobx-state-tree';
import { ClientJS } from 'clientjs';

import api from 'services/API';
import { Notification, Condition, UserDevice } from 'models/types';
import { getRootStore } from 'models/root';
import orderBy from 'lodash/orderBy';

import { numberUtilities, stringUtilities } from 'utils';

const client = new ClientJS();

export const notificationInitialState = {
  isLoaded: false,
  all: [],
  conditions: [],
  selectedAlertId: null,
  devices: [],
  deviceFilters: {
    sortedBy: 'device_name',
    orderBy: 'desc',
  },
  searchString: '',
  state: 'done',
};

export const notificationModel = types
  .model({
    isLoaded: types.boolean,
    all: types.array(Notification),
    devices: types.array(UserDevice),
    deviceFilters: types.maybeNull(
      types.model({
        sortedBy: types.enumeration('sortedBy', ['device_name', 'type', 'created_at']),
        orderBy: types.enumeration('orderBy', ['asc', 'desc']),
      }),
    ),
    conditions: types.array(Condition),
    selectedAlertId: types.maybeNull(types.number),
  })
  .views(self => ({
    get deviceItems() {
      const root = getRootStore();

      let filtered = self.devices.filter(device => device.user_id === root.userStore.profile.id);
      if (self.searchString) {
        const searchArray = self.searchString?.toLowerCase().split(' ');

        filtered = self.devices.filter(item =>
          searchArray.every(element => item.device_name.includes(element)),
        );
      }

      const result = orderBy(filtered, [self.deviceFilters.sortedBy], [self.deviceFilters.orderBy]);
      return result;
    },
    get extended() {
      const root = getRootStore();

      return self.all.map(alert => {
        const condition = self.conditions.find(c => c.id === alert.condition_id);

        const alarms = root.deviceHistoryStore.alarms.filter(
          ({ sensor_id }) => sensor_id === condition?.context?.id,
        );

        const min = numberUtilities.celsiusToFahrenheit(
          stringUtilities.ruleToValue(alarms.find(a => a?.rule.includes('<'))?.rule),
          true,
        );
        const max = numberUtilities.celsiusToFahrenheit(
          stringUtilities.ruleToValue(alarms.find(a => a?.rule.includes('>'))?.rule),
          true,
        );

        return {
          ...alert,
          condition,
          alarms,
          min,
          max,
        };
      });
    },
    get unread() {
      return self.extended
        .filter(n => n.status_code === 0)
        .sort(
          (a, b) =>
            Date.parse(b._conditions_earliest_trigger_at) -
            Date.parse(a._conditions_earliest_trigger_at),
        );
    },
    get selectedAlert() {
      return self.extended.find(alert => alert.id === self.selectedAlertId);
    },

    get fingerprint() {
      return client.getFingerprint();
    },

    get isDeviceExist() {
      return self.devices.find(device => device.device_fingerprint === self.fingerprint);
    },
  }))
  .actions(self => ({
    fetchDevices: flow(function* () {
      try {
        self.state = 'pending';
        const response = yield api.getUserDevices();

        if (response?.data?.result) {
          self.devices.replace(response.data.result);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),
    patchUserDevices: flow(function* (id, data) {
      try {
        self.state = 'pending';
        const response = yield api.patchUserDevices(id, data);

        if (response?.data) {
          self.devices.replace([
            ...self.devices.filter(e => e.id !== response.data.row.id),
            response.data.row,
          ]);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),

    registerUserDevice: flow(function* ({ device_name, subscription, notifications_muted }) {
      try {
        self.state = 'pending';

        const response = yield api.registerUserDevice({
          device_name,
          subscription,
          device_fingerprint: self.fingerprint,
          notifications_muted,
        });

        if (response?.data) {
          self.devices.replace([...self.devices, response.data.result[0]]);
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.result;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),

    removeDevice: flow(function* (item) {
      try {
        self.state = 'pending';
        const response = yield api.deleteUserDevice(item.id);

        if (response?.data) {
          self.devices.replace([...self.devices.filter(e => e.id !== response.data.row.id)]);
          if (localStorage.getItem('bar_track_pwa_device_identifier') === item.identifier) {
            localStorage.removeItem('bar_track_pwa_device_identifier');
          }
          self.state = 'done';
          self.isLoaded = true;
          self.updated = new Date();
          return response.data.row;
        } else {
          self.isLoaded = false;
          self.state = 'done';
          self.updated = new Date();
        }
      } catch (error) {
        self.isLoaded = false;
        self.state = 'error';
        self.updated = new Date();
        console.error(error);
        return Promise.reject(error);
      }
    }),
    markAsRead: flow(function* (id) {
      try {
        const response = yield api.patchNotification({ status_code: 1 }, id);
        const updatedNotification = response.data?.row;

        self.all.replace([
          ...self.all.filter(e => e.id !== updatedNotification.id),
          updatedNotification,
        ]);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    testDeviceNotification: flow(function* (device_id) {
      try {
        yield api.testUserDeviceNotification(device_id);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    }),

    setUserDevices(user_devices) {
      self.devices.replace(user_devices);
    },

    setConditions(conditions) {
      self.conditions.replace(
        conditions.map(condition => ({
          ...condition,
          criteria_value: numberUtilities.celsiusToFahrenheit(
            stringUtilities.ruleToValue(condition.criteria),
            true,
          ),
        })),
      );
    },

    setSearchString(value) {
      self.searchString = value;
    },

    setDeviceFilters(filters) {
      if (filters === null) {
        self.deviceFilters = {
          sortedBy: 'timestamp',
          orderBy: 'desc',
        };
      } else {
        self.deviceFilters = {
          sortedBy: filters.sortedBy || self.deviceFilters.sortedBy,
          orderBy: filters.orderBy || self.deviceFilters.orderBy,
        };
      }
    },

    setAlertId(id = null) {
      self.selectedAlertId = Number(id);
    },
  }));
