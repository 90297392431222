import { useStore } from 'hooks';
import { useMemo } from 'react';

import dateUtilities from 'utils/dateUtilities';

/**
 * Retrieves and processes the offline status for various system components including gateways, BRUs, sensors, and devices.
 * Uses data from the topology management store to filter items and calculate whether each item is online.
 *
 * @return {Object} An object containing the offline status and total count for gateways, BRUs, sensors, and devices.
 * The structure of the returned object is:
 * - gateways: { total: number, offline: Array }
 * - brus: { total: number, offline: Array }
 * - sensors: { total: number, offline: Array }
 * - devices: { total: number, offline: Array }
 *   - 'total' represents the total number of items.
 *   - 'offline' is an array of items that are offline.
 */
const useSystemOfflineData = () => {
  const { topologyManagementStore } = useStore();

  const coolers = topologyManagementStore.getFilteredItemsByKey('cooler');
  const gateways = topologyManagementStore.getFilteredItemsByKey('gateway');
  const brus = topologyManagementStore.getFilteredItemsByKey('bru');
  const sensors = topologyManagementStore.getFilteredItemsByKey('sensor');

  const gatewayStatusList = useMemo(
    () =>
      gateways.map(gateway => {
        const cooler = coolers.find(({ id }) => id === gateway.cooler_id);
        const { latest_heartbeat_received_at, latest_sample_received_at } = gateway;
        const mostRecentDate = dateUtilities.getMostRecentDate([
          latest_heartbeat_received_at,
          latest_sample_received_at,
        ]);
        const isOnline = dateUtilities.isOnline(mostRecentDate);

        return { ...gateway, isOnline, cooler };
      }),
    [gateways, coolers],
  );

  const sensorStatusList = useMemo(
    () =>
      sensors.map(sensor => {
        const { latest_heartbeat_received_at, bru_id, _gateways_id } = sensor;
        const bru = brus.find(({ id }) => id === bru_id);
        const gateway = gateways.find(({ gateway_id }) => gateway_id === _gateways_id);
        const cooler = coolers.find(({ id }) => id === gateway.cooler_id);
        const isOnline = dateUtilities.isOnline(latest_heartbeat_received_at);

        return {
          ...sensor,
          isOnline,
          gateway,
          bru,
          gateway_bru_address: bru?.gateway_bru_address,
          gateway_name: gateway?.name,
          cooler,
        };
      }),
    [brus, gateways, sensors, coolers],
  );

  return {
    gateways: {
      total: gateways.length,
      offline: gatewayStatusList.filter(gateway => gateway.isOnline === false),
    },
    sensors: {
      total: sensors.length,
      offline: sensorStatusList.filter(sensor => sensor.isOnline === false),
    },
    devices: {
      total: gateways.length + sensors.length,
      offline: [...gatewayStatusList, ...sensorStatusList].filter(
        device => device.isOnline === false,
      ),
    },
  };
};

export default useSystemOfflineData;
