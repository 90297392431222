import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Portal } from '@mui/material';
import DetailsDrawer from 'components/shared/SystemOffline/DetailsDrawer';
import { observer } from 'mobx-react';
import { differenceInMinutes } from 'date-fns';
import Desktop from './desktop';
import Mobile from './mobile';
import { useMediaQueries, useReferenceContext, useStore } from 'hooks';
const REFRESH_TIME = 300;
const INITIAL_TIME = 5;

const SystemOfflineBanner = () => {
  const [refreshTimeLeft, setRefreshTimeLeft] = useState(REFRESH_TIME);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [onlineCountdown, setOnlineCountdown] = useState(INITIAL_TIME);
  const [isBackOnline, setIsBackOnline] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const { ui, topologyManagementStore, establishmentStore } = useStore();
  const { systemOfflineBanner } = useReferenceContext();
  const { isMobileOrSmaller, isTabletOrGreater } = useMediaQueries();

  const closeBanner = () => {
    ui.setSystemOffline(false);
  };

  useEffect(() => {
    let timer = null;

    if (ui.systemStatus.restored) {
      clearInterval(timer);
      setIsRefreshing(false);
      return;
    }

    if (isRefreshing && refreshTimeLeft > 0) {
      timer = setInterval(() => {
        setRefreshTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (refreshTimeLeft === 0) {
      if (
        !establishmentStore?.establishment?.systemLastUpdated ||
        differenceInMinutes(
          new Date(),
          new Date(establishmentStore?.establishment?.systemLastUpdated),
        ) >= 5
      ) {
        topologyManagementStore.fetchEstablishmentsSensors();
      }
      clearInterval(timer);
      setIsRefreshing(false);
      setRefreshTimeLeft(REFRESH_TIME);
    }
    return () => clearInterval(timer);
  }, [
    isRefreshing,
    refreshTimeLeft,
    ui.systemStatus.restored,
    topologyManagementStore,
    establishmentStore?.establishment?.systemLastUpdated,
  ]);

  useEffect(() => {
    let timer = null;

    if (isBackOnline && onlineCountdown > 0) {
      timer = setInterval(() => {
        setOnlineCountdown(prevTime => prevTime - 1);
      }, 1000);
    } else if (onlineCountdown === 0) {
      clearInterval(timer);
      setIsBackOnline(false);
      setOnlineCountdown(INITIAL_TIME);
    }

    return () => clearInterval(timer);
  }, [isBackOnline, onlineCountdown, ui.systemStatus.restored]);

  const startRefreshCountdown = async () => {
    try {
      await topologyManagementStore.fetchEstablishmentsSensors();
      if (refreshTimeLeft === 0) {
        setRefreshTimeLeft(REFRESH_TIME);
      }
      if (!ui.systemStatus.restored) {
        setIsRefreshing(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (ui.systemStatus.restored) {
      setIsBackOnline(true);
    }
  }, [ui.systemStatus.restored]);

  const closeDetailsDrawer = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };

  const handleDetailsClick = () => {
    setDetailsDrawerOpen(!detailsDrawerOpen);
  };

  const handleNavigateToHardwareManagement = () => {
    navigate('/system-configuration/topology-management');
  };

  if (ui.systemStatus.offline) {
    return (
      <>
        <Portal container={systemOfflineBanner.ref.current}>
          {isTabletOrGreater && (
            <Desktop
              fullSystemOffline={ui.systemStatus.fullSystem}
              systemRestored={ui.systemStatus.restored}
              exitBanner={closeBanner}
              refreshTimeLeft={refreshTimeLeft}
              isRefreshing={isRefreshing}
              handleRefresh={startRefreshCountdown}
              onlineCountdown={onlineCountdown}
              isBackOnline={isBackOnline}
              handleDetailsClick={handleDetailsClick}
            />
          )}
          {isMobileOrSmaller && (
            <Mobile
              fullSystemOffline={ui.systemStatus.fullSystem}
              systemRestored={ui.systemStatus.restored}
              exitBanner={closeBanner}
              handleRefresh={startRefreshCountdown}
              isRefreshing={isRefreshing}
              refreshTimeLeft={refreshTimeLeft}
              onlineCountdown={onlineCountdown}
              isBackOnline={isBackOnline}
              handleDetailsClick={handleDetailsClick}
            />
          )}
        </Portal>
        <DetailsDrawer
          handleClose={closeDetailsDrawer}
          isOpen={detailsDrawerOpen}
          handleNavigateToHardwareManagement={handleNavigateToHardwareManagement}
        />
      </>
    );
  }
};

export default observer(SystemOfflineBanner);
