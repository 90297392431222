import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import clsx from 'clsx';

import { dateUtilities, numberUtilities } from 'utils';
import { useStore } from 'hooks';

const GeneralTab = ({ sensor }) => {
  const { topologyManagementStore } = useStore();
  const handleRounding = val => (val ? numberUtilities.roundToDecimal(val) : 'N/A');

  const INFO = useMemo(() => {
    const { signal_strength, sensor_vfr, sensor_temp_c } =
      topologyManagementStore.selectedSensorMetadata || {};
    const { VOLUME_SCALE_FACTOR, PGA_GAIN, CALIBRATED_TOF } = sensor?.metadata || {};
    const sensorContentRows = [
      [
        {
          title: 'Signal Strength',
          value: handleRounding(signal_strength),
        },
        {
          title: 'VolScale',
          value: handleRounding(VOLUME_SCALE_FACTOR),
        },
      ],
      [
        {
          title: 'Gain',
          value: handleRounding(PGA_GAIN),
        },
        {
          title: 'CalTOF',
          value: numberUtilities.toExponential(CALIBRATED_TOF),
        },
      ],
      [
        {
          title: 'VFR',
          value: handleRounding(sensor_vfr),
        },
        {
          title: 'Temperature',
          value: handleRounding(sensor_temp_c),
        },
      ],
    ];

    return sensorContentRows.map((row, rowIndex) => (
      <Box
        className={clsx('item row', {
          hidden: !topologyManagementStore.selectedSensorMetadata,
        })}
        key={rowIndex}
      >
        {row.map(({ title, value }, elementIndex) => (
          <Box className="item_row" key={rowIndex * 10 + elementIndex}>
            <Typography
              gutterBottom
              color={({ palette }) => palette.whiteEmphasis.medium}
              variant="h8"
            >
              {title}
            </Typography>
            <Typography variant="h7">{value}</Typography>
          </Box>
        ))}
      </Box>
    ));
  }, [sensor.metadata, topologyManagementStore.selectedSensorMetadata]);

  return (
    <StyledRootBox>
      <Box className="item">
        <Typography gutterBottom color={({ palette }) => palette.whiteEmphasis.medium} variant="h8">
          Latest Pour
        </Typography>
        <Typography variant="h7">
          {dateUtilities.formatDateIncludingToday(sensor.latest_pour_received_at)}
        </Typography>
      </Box>
      {INFO}
    </StyledRootBox>
  );
};

export default observer(GeneralTab);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',

  '&.hidden': {
    visibility: 'hidden',
  },

  '.item': {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
    borderBottom: `1px solid ${palette.secondary.main}`,

    '&.row': {
      flexDirection: 'row',
    },
  },

  '.item_row': {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '50%',

    '&.hidden': {
      visibility: 'hidden',
    },
  },
}));
