import React, { useState, useCallback, useMemo } from 'react';

import { Box, Button, Drawer, Grid, TextField, Typography, styled } from '@mui/material';
import { Close } from '@mui/icons-material';
import { observer } from 'mobx-react';

import { format, isValid, isFuture, differenceInDays } from 'date-fns';

import palette from 'theme/palette';

import dateUtilities from 'utils/dateUtilities';
import DatePicker from './DatePicker';

const CalendarDrawer = ({ open, onClose, onApply }) => {
  const formatPattern = 'MM/dd/yyyy';
  const yesterday = dateUtilities.getYesterday();
  const patternRegex = useMemo(() => /(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/20\d{2}/, []);

  const isValidDate = useCallback(
    date => patternRegex.test(date) && isValid(new Date(date)) && !isFuture(new Date(date)),
    [patternRegex],
  );

  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(yesterday);
  const [startDateDisplay, setStartDateDisplay] = useState(format(yesterday, formatPattern));
  const [endDateDisplay, setEndDateDisplay] = useState(format(yesterday, formatPattern));

  const [periodError, setPeriodError] = useState('');

  const applyDate = useCallback(() => {
    if (differenceInDays(endDate, startDate) > 31) {
      setPeriodError('Date range must be less than 31 days');
      return;
    } else {
      onApply({ from: startDate, to: endDate });
      onClose();
    }
  }, [startDate, endDate, onClose, onApply]);

  const handleBlurStart = useCallback(() => {
    if (isValidDate(startDateDisplay)) {
      setStartDate(new Date(startDateDisplay));
    } else {
    }
  }, [isValidDate, startDateDisplay]);

  const handleBlurEnd = useCallback(() => {
    if (isValidDate(endDateDisplay)) {
      setEndDate(new Date(endDateDisplay));
    } else {
    }
  }, [isValidDate, endDateDisplay]);

  const handleRangeChange = props => {
    setPeriodError('');

    setStartDate(props.selection.startDate);
    setEndDate(props.selection.endDate);
    setStartDateDisplay(format(props.selection.startDate, formatPattern));
    setEndDateDisplay(format(props.selection.endDate, formatPattern));
  };

  return (
    <StyledRootDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      sx={{ zIndex: 1300 }}
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        },
      }}
    >
      <Box sx={{ width: 'auto' }} role="presentation">
        <Grid className="grid_container" container p={1}>
          <Grid item xs={2}>
            <Box p={1} onClick={onClose}>
              <Close />
            </Box>
          </Grid>
          <Grid item xs={8} display="flex" justifyContent="center" alignItems="center">
            <Typography textAlign="center" fontSize={20}>
              Custom Date
            </Typography>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Box className="error_box">
          {Boolean(periodError) && <Typography className="error_text">{periodError}</Typography>}
        </Box>
        <Box className="calendar_box">
          <Box display={'flex'} flexDirection={'column'} px={2}>
            <Box className="boxTitle" mb={1.5}>
              <Typography variant={'h8'} color={palette.whiteEmphasis.low}>
                Select your date range:
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Start"
                    value={startDateDisplay}
                    variant="outlined"
                    onChange={({ target: { value } }) => setStartDateDisplay(value)}
                    onBlur={handleBlurStart}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="End"
                    value={endDateDisplay}
                    variant="outlined"
                    onChange={({ target: { value } }) => setEndDateDisplay(value)}
                    onBlur={handleBlurEnd}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              handleRangeChange={handleRangeChange}
            />
          </Box>
        </Box>
        <Box display="flex" m={2}>
          <Button
            className="capitalize"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ borderRadius: 10 }}
            onClick={applyDate}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </StyledRootDrawer>
  );
};

const StyledRootDrawer = styled(Drawer)(({ theme: { palette } }) => ({
  '.grid_container': {
    borderBottom: `1px solid ${palette.background.overlay6}`,
  },
  '.calendar_box': {
    borderBottom: `1px solid ${palette.background.overlay6}`,
  },
  '.error_box': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 12px',
    '.error_text': {
      color: palette.status.red,
    },
  },
}));

export default observer(CalendarDrawer);
