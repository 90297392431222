import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { Box, Typography, styled } from '@mui/material';

import { numberUtilities } from 'utils';
import { useStore } from 'hooks';
import Loader from 'components/shared/Loader';

import PeriodButtonGroup from './PeriodButtonGroup';
import ChartDetails from './ChartDetails';

const GeneralTab = ({ device, type: deviceType, dataset }) => {
  const handleRounding = val => (val ? numberUtilities.roundToDecimal(val) : 'N/A');
  const { topologyManagementStore } = useStore();
  const INFO = useMemo(() => {
    const { signal_strength, sensor_vfr, sensor_temp_c } =
      topologyManagementStore.selectedSensorMetadata || {};

    const sensorContent = [
      {
        title: 'Signal Strength',
        value: handleRounding(signal_strength),
      },
      {
        title: 'Gain',
        value: handleRounding(device?.metadata?.PGA_GAIN),
      },
      {
        title: 'VFR',
        value: handleRounding(sensor_vfr),
      },
      {
        title: 'VolScale',
        value: handleRounding(device?.metadata?.VOLUME_SCALE_FACTOR),
      },
      {
        title: 'CalTOF',
        value: numberUtilities.toExponential(device?.metadata?.CALIBRATED_TOF),
      },
      {
        title: 'Temperature',
        value: handleRounding(sensor_temp_c),
      },
    ];

    return (
      <Box
        className={clsx('info_box flex', {
          hide: deviceType !== 'sensor',
        })}
      >
        <Box className="full flex" ml={1} mb={2}>
          <Typography className="sensor_title">Info</Typography>
        </Box>
        <Box
          className={clsx('flex full', {
            hidden: !topologyManagementStore.selectedSensorMetadata,
          })}
        >
          {sensorContent.map(({ title, value }, key) => (
            <Box className="item_box flex" key={key}>
              <Typography className="title">{title}</Typography>
              <Typography className="subtitle">{value}</Typography>
            </Box>
          ))}
        </Box>
        <Typography
          className={clsx('loading_title', {
            hidden: topologyManagementStore.selectedSensorMetadata,
          })}
        >
          Loading Sensor Info...
        </Typography>
      </Box>
    );
  }, [deviceType, topologyManagementStore.selectedSensorMetadata, device]);

  return (
    <StyledRootBox>
      <Box className="tab_content">
        <Box className="general_box flex">
          <Box className="full flex between" px={2}>
            <Typography className="connectivity_title">Connectivity</Typography>
            <PeriodButtonGroup />
          </Box>
          <Box
            className={clsx('content_chart_box', {
              hidden: !topologyManagementStore.isLoaded,
            })}
          >
            <ChartDetails device={device} type={deviceType} data={dataset} />
          </Box>
          <Box
            className={clsx('content_chart_box', {
              hidden: topologyManagementStore.isLoaded,
            })}
          >
            <Loader
              progressMessage="Loading Connectivity History ..."
              indent={0}
              sx={{ position: 'static', height: 'auto' }}
            />
          </Box>
        </Box>

        {INFO}
      </Box>
    </StyledRootBox>
  );
};

export default observer(GeneralTab);

const StyledRootBox = styled(Box)(({ theme: { palette } }) => ({
  '.clickable': {
    cursor: 'pointer',
  },
  '.hidden': {
    display: 'none !important',
  },

  '.flex': {
    display: 'flex',
    alignItems: 'center',
  },

  '.full': {
    width: '100%',
  },

  '.around': {
    justifyContent: 'space-around',
  },
  '.between': {
    justifyContent: 'space-between',
  },
  '.sticky': {
    position: 'sticky',
    top: 0,
    paddingBottom: '16px',
    backgroundColor: palette.secondary.greyLight,

    p: {
      fontSize: '11px',
      color: palette.whiteEmphasis.medium,
    },
  },

  '.sensor_title': {
    fontSize: '20px',
    color: palette.whiteEmphasis.high,
    display: 'inline',
    marginRight: '8px',
  },
  '.sensor_subtitle': {
    fontSize: '14px',
    color: palette.whiteEmphasis.medium,
    display: 'inline',
  },

  '.icon_8': {
    width: '8px',
    height: '8px',
  },
  '.icon_16': {
    width: '16px',
    height: '16px',
    color: palette.whiteEmphasis.medium,
  },
  '.icon_40': {
    width: '40px',
    height: '40px',
    marginRight: '16px',
  },

  '.red': {
    color: palette.status.red,
  },
  '.green': {
    color: palette.status.green,
  },
  padding: '16px',
  backgroundColor: palette.background.main,

  '.tab_content': {
    padding: '16px',
    borderRadius: '12px',
    backgroundColor: palette.secondary.greyLight,
  },

  '.general_box': {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '360px',
    flexDirection: 'column',
  },

  '.connectivity_title': {
    fontSize: '20px',
  },

  '.content_chart_box': {
    height: '300px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.info_box': {
    height: '94px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '16px',

    '&.hide': {
      visibility: 'hidden',
    },
  },

  '.item_box': {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    alignItems: 'start',
  },

  '.subtitle': {
    fontSize: '14px',
    color: palette.whiteEmphasis.high,
  },
  '.title': {
    fontSize: '11px',
    color: palette.whiteEmphasis.medium,
  },
  '.divider': {
    color: palette.whiteEmphasis.medium,
  },

  '.loading_title': {
    fontSize: '20px',
    color: palette.whiteEmphasis.high,
  },
}));
